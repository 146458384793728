/*------------------------------------------------------------
Project Name: VS-Theme-17;
------------------------------------------------------------*/

/* Reset
--------------------------------------------------------------------------------------------------------*/
/*http://meyerweb.com/eric/tools/css/reset/v2.0 | 20110126License:none (public domain)*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  min-width: 375px;
  color: #1a1a1a;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  list-style-type: none;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #333333;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background: #ededed !important;
}

::-webkit-scrollbar-thumb {
  border: 0 !important;
  background-color: #c9c9c9 !important;
  border-radius: 12px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
