@import "reset";
@import "spacing";
@import "variables";

/* misc
--------------------------------------------------------------------------------------------------------*/
.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.outer-card {
  margin: 0 24px;
  background: $background;
  border-radius: 24px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* header
--------------------------------------------------------------------------------------------------------*/
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  min-height: 96px;
  background: $white;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  transform: none !important;

  &.page-scrolled {
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
      rgb(0 0 0 / 5%) 0px 4px 6px -2px;
  }

  .MuiIconButton-root {
    margin-left: -10px;
    display: none;
  }

  .MuiLink-root {
    color: $secondary-text;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: 0.25px;
  }

  .logo {
    display: block;

    img {
      display: block;
      height: 48px;
      width: 192px;

      &.mobile-logo {
        display: none;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 4px;

    li {
      &.has-submenu {
        a {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: space-between;
          padding-right: 12px;

          &.menu-expanded {
            color: $secondary-text;
          }

          img {
            transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &.submenu {
      display: block;
      position: absolute;
      box-shadow: 0px 20px 50px -12px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      background-color: $white;
      padding: 12px 0;
      width: 320px;
      border-radius: 16px;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      visibility: hidden;

      li {
        a {
          @include body-2();
          color: $primary-text;
          padding: 8px 24px;
          transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
          border-radius: 0;

          &:hover {
            background: $primary-50;
          }

          &::after {
            opacity: 0;
          }
        }
      }
    }

    li {
      position: relative;

      a {
        @include body-2-medium();
        color: $secondary-text;
        padding: 8px 16px;
        border-radius: 24px;
        display: block;
        position: relative;
        z-index: 1;

        &.active {
          background: $primary-50;
          color: $primary;
        }

        &:not(.active) {
          &::after {
            content: "";
            display: block;
            position: absolute;
            background: $primary-50;
            left: 50%;
            height: 100%;
            width: 100%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0, 1);
            transform-origin: center;
            border-radius: 30px;
            z-index: -1;
            transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
          }

          &:hover {
            &::after {
              transform: translate(-50%, -50%) scale(1, 1);
            }
          }
        }
      }

      &:hover {
        .submenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/* hero banner
--------------------------------------------------------------------------------------------------------*/
.banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.48);
    z-index: 0;
  }

  &.page-banner {
    min-height: 520px;
    padding-top: 156px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-content {
      margin: -4px auto 0;

      .MuiBreadcrumbs-root {
        margin-bottom: 12px;

        .MuiBreadcrumbs-ol {
          justify-content: center;
        }

        .MuiBreadcrumbs-li {
          a {
            color: $white;
          }

          p {
            color: $white;
            opacity: 0.88;
          }
        }
      }

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  &.industry-details-banner {
    margin-bottom: 100px;

    .banner-content {
      max-width: 1024px;
    }
  }

  &.service-details-banner {
    padding-top: 96px;
    margin-bottom: 80px;

    .MuiBreadcrumbs-root {
      margin-bottom: 12px;
    }

    h2 {
      padding-bottom: 40px;
    }

    .MuiButton-contained {
      min-width: 190px;
    }

    &::after {
      display: none;
    }

    &.bg-light-purple {
      background: #f1f1ff;

      h2 {
        color: #7b7bb6;
      }

      .MuiButton-contained {
        background: #7b7bb6;

        &::after {
          background: #56567f;
        }
      }
    }

    &.bg-light-blue {
      background: #def4ff;

      h2 {
        color: #4597bf;
      }

      .MuiButton-contained {
        background: #4597bf;

        &::after {
          background: #306a86;
        }
      }
    }

    &.bg-light-pink {
      background: #f6edff;

      h2 {
        color: #8f71ad;
      }

      .MuiButton-contained {
        background: #8f71ad;

        &::after {
          background: #644f79;
        }
      }
    }

    .banner-content {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        padding: 48px 48px 48px 0;
      }

      .right {
        img {
          display: block;
          height: 471px;
          width: auto;
        }
      }
    }
  }

  .banner-content {
    max-width: 800px;
    position: relative;
    z-index: 1;

    h2 {
      color: $white;

      span {
        color: $primary;
      }
    }

    p {
      color: rgba(255, 255, 255, 0.88);
    }
  }

  &.hero-banner {
    background-image: url("../images/hero-banner.webp");
    padding-top: 204px;
    padding-bottom: 132px;

    h2 {
      padding-bottom: 40px;
    }

    p {
      padding-bottom: 60px;
    }

    .MuiButton-root {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

/* service-details-content
--------------------------------------------------------------------------------------------------------*/
.service-details-content {
  > p {
    padding-bottom: 80px;
    color: $secondary-text;
  }
}

#contactusloader{
  margin-bottom: -16px;
  margin-left: 8px;
}

/* about-it-solution
--------------------------------------------------------------------------------------------------------*/
.about-it-solution {
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  h3 {
    color: $primary-text;
  }

  p {
    color: $secondary-text;
  }
}

/* counter
--------------------------------------------------------------------------------------------------------*/
.counter {
  border-radius: 24px;
  background: $primary;
  padding: 60px 0;

  ul {
    display: flex;

    li {
      flex: 1;
      padding-left: 16px;
      padding-right: 16px;

      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.16);
      }

      span {
        @include heading-2();
        color: $white;
        display: block;
        text-align: center;
        padding-bottom: 8px;
      }

      p {
        font-weight: 500;
        text-align: center;
        color: $white;
        opacity: 0.8;
      }
    }
  }
}

/* slick-slider
--------------------------------------------------------------------------------------------------------*/
.slick-slider {
  margin-bottom: 48px;

  .MuiButtonBase-root {
    &.slick-arrow {
      height: 44px;
      width: 44px;
      border: 2px solid #999999;
      display: flex !important;
      position: absolute;
      top: -103px;
      right: 0;
      left: unset;
      transform: none;

      &.slick-prev {
        right: 57px;
      }

      &.slick-disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: 50%;
        left: 50%;
        background: #0487ff;
        border-radius: 30px;
        z-index: 0;
        transform: translate(-50%, -50%) scale(0, 1);
        transform-origin: center;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &::before {
        content: none;
      }

      &:hover {
        background: transparent;
        border-color: #0487ff;

        img {
          width: auto;
          height: auto;

          &.active {
            opacity: 1;
            visibility: visible;
          }

          &:not(.active) {
            opacity: 0;
            visibility: hidden;
          }
        }

        &::after {
          transform: translate(-50%, -50%) scale(1, 1);
        }
      }
    }
  }
}

/* slider card
--------------------------------------------------------------------------------------------------------*/
.slider-card {
  .top {
    padding-right: 140px;

    h3 {
      color: $primary-text;
    }

    p {
      color: $secondary-text;
    }
  }

  .slick-list {
    margin: 0 -16px;
    overflow: visible;

    .slick-track {
      overflow: visible;
      display: flex;
      position: relative;

      .slick-slide {
        padding: 0 16px;
        float: none;
        height: auto;

        > div {
          height: 100%;
        }
      }
    }
  }

  a {
    margin: 0 auto;
  }
}

/* our services
--------------------------------------------------------------------------------------------------------*/
.our-services {
  padding-top: 100px;
  padding-bottom: 60px;
  overflow: hidden;
}

/* service card
--------------------------------------------------------------------------------------------------------*/
.MuiCard-root.service-card {
  height: 100%;
  border: 1px solid $light-border;
  display: flex;
  flex-direction: column;
  transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 4%) 0px 10px 10px -5px;
  }

  .MuiCardContent-root {
    padding: 32px 32px 16px;

    .icon {
      padding: 12px;
      border-radius: 12px;
      margin-bottom: 24px;
      width: 72px;

      &.yellow {
        background-color: #fff2da;
      }

      &.light-green {
        background-color: #c7fff8;
      }

      &.green {
        background-color: #dfffe6;
      }

      &.blue {
        background-color: $primary-50;
      }

      &.pink {
        background-color: #fee5ff;
      }

      &.purple {
        background-color: #f1efff;
      }

      img {
        display: block;
        height: 48px;
        width: 48px;
      }
    }

    h6 {
      font-weight: 700;
      color: $primary-text;
      padding-bottom: 12px;
    }

    p {
      color: $secondary-text;
    }
  }

  .MuiCardActions-root {
    padding: 0 32px 32px;
    margin-top: auto;

    a {
      margin: 0;
    }

    .MuiButton-root {
      margin-left: -24px;
      margin-bottom: -19px;

      img {
        width: auto;
        height: auto;
        transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &::after {
        display: none;
      }

      &:hover {
        background: none;

        img {
          width: auto;
          height: auto;
          transform: translateX(8px);
        }
      }
    }
  }
}

/* industries we serve
--------------------------------------------------------------------------------------------------------*/
.industries-we-serve {
  overflow: hidden;
  padding-bottom: 120px;
}

/* industry card
--------------------------------------------------------------------------------------------------------*/
.MuiCard-root.industry-card {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
      rgb(0 0 0 / 4%) 0px 10px 10px -5px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 24px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .MuiCardContent-root {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(32px);
    z-index: 2;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 0;

    p {
      color: $white;
    }

    a {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      min-height: 88px;
      padding: 12px 16px 12px 24px;

      &:hover {
        .MuiButtonBase-root {
          &::after {
            transform: translate(-50%, -50%) scale(1, 1);
          }
        }
      }
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      left: 32px;
      right: 32px;
      top: 0;
      position: absolute;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 1;
    }

    .MuiIconButton-root {
      img {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }
}

/* software development technologies
--------------------------------------------------------------------------------------------------------*/
.software-development-technologies {
  border-radius: 24px;
  padding: 80px;
  min-height: 629px;
  background-image: url("../images/software-development-technologies.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 66, 126, 0.88) 0%,
      rgba(0, 66, 126, 0.16) 100%
    );
    border-radius: 24px;
    z-index: 0;
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    > span {
      color: rgba(255, 255, 255, 0.88);
      padding-bottom: 40px;
      text-align: center;
    }
  }

  h4 {
    color: $white;
    text-align: center;
  }

  p {
    color: rgba(255, 255, 255, 0.88);
    text-align: center;
  }
}

/* how we work
--------------------------------------------------------------------------------------------------------*/
.how-we-work {
  overflow: hidden;

  .outer-card {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
  }

  h3 {
    max-width: 800px;
    text-align: center;
    color: $primary-text;
    margin: 0 auto;
  }

  p {
    max-width: 800px;
    text-align: center;
    color: $secondary-text;
    margin: 0 auto;
  }

  ul {
    max-width: 600px;
    margin: 0 auto;

    li {
      &:not(:last-child) {
        .step {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            top: 2px;
            bottom: -2px;
            background: $primary;
            background: $dark-border;
            z-index: 0;
          }
        }
      }

      .step {
        max-width: 305px;
        position: relative;
        padding-bottom: 16px;

        p {
          @include subtitle-3();
          text-transform: uppercase;
          color: $primary;
        }

        span {
          font-weight: 600;
          color: $primary-text;
          display: block;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 24px;
          border: 4px solid $background;
          top: 2px;
          background: $primary;
          z-index: 1;
        }
      }

      &:nth-child(even) {
        .step {
          margin-right: auto;
          padding-right: 22px;

          p {
            text-align: end;
          }

          span {
            text-align: end;
          }

          &::before {
            right: -4px;
          }

          &::after {
            right: 4.5px;
          }
        }
      }

      &:nth-child(odd) {
        .step {
          margin-left: auto;
          padding-left: 22px;

          p {
            text-align: start;
          }

          span {
            text-align: start;
          }

          &::before {
            left: -4px;
          }

          &::after {
            left: 4.5px;
          }
        }
      }
    }
  }

  img {
    display: block;
    position: absolute;
    bottom: -10px;
    width: auto;
    height: auto;

    &.illustration-1 {
      left: -50px;
    }

    &.illustration-2 {
      right: -50px;
    }
  }
}

/* why choose us
--------------------------------------------------------------------------------------------------------*/
.why-choose-us {
  h3 {
    color: $primary-text;
    text-align: center;
  }

  p {
    color: $secondary-text;
    margin: 0 auto;
    text-align: center;
  }

  .MuiGrid-container {
    overflow: hidden;
    border-radius: 24px;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border: 1px solid $primary-50;
    }
  }

  .MuiCard-root {
    background: $primary-50;
    height: 100%;
    border-radius: 0;
    border-bottom: 1px solid $primary-150;
    border-right: 1px solid $primary-150;

    .MuiCardContent-root {
      padding: 32px;

      img {
        display: block;
        margin-bottom: 22px;
        height: 58px;
        width: 58px;
      }

      p {
        color: $primary-text;
        text-align: left;
      }

      span {
        color: $secondary-text;
      }
    }
  }
}

/* our-clients
--------------------------------------------------------------------------------------------------------*/
.our-clients {
  padding: 80px 0;
  overflow: hidden;

  h3 {
    color: $primary-text;
    text-align: center;
  }

  p {
    max-width: 800px;
    text-align: center;
    margin: 0 auto 40px;
    color: $secondary-text;
  }

  ul {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 64px;
    max-width: 1024px;

    a {
      text-decoration: none;
      @include body-3-medium();
      color: $primary;
      padding: 0 12px 0 24px;
      margin-bottom: 12px;
      display: block;
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 4px;
        width: 4px;
        border-radius: 10px;
        background: $primary;
        left: 12px;
        top: 9px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .slick-slider {
    margin-bottom: 0;
    opacity: 0.6;

    .slick-list {
      .slick-track {
        .slick-slide {
          padding: 0 40px;

          img {
            display: block !important;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

/* testimonials
--------------------------------------------------------------------------------------------------------*/
.testimonials {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    top: 0;
    bottom: 0;
    right: -40px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    z-index: 1;
  }

  .content {
    max-width: 480px;
    padding-top: 90px;

    h3 {
      color: $primary-text;
    }

    p {
      color: $secondary-text;
    }
  }

  .slider-wrapper {
    overflow: hidden;
    margin-right: -40px;
  }

  .slick-slider {
    max-width: 412px;
    position: static;
    margin-bottom: 0;

    .MuiButtonBase-root {
      &.slick-arrow {
        right: unset;
        left: 0;
        top: 394px;

        &.slick-next {
          left: 56px;
        }
      }
    }
    .slick-list {
      overflow: visible;

      .slick-track {
        display: flex;

        .slick-slide {
          float: none;
          min-height: 496px;
          display: flex;
          margin-right: 4px;
          transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

          &:not(.slick-active) {
            transform: scale(0.9);
            opacity: 0.48;
          }
        }
      }
    }
  }
}

/* testimonial-card
--------------------------------------------------------------------------------------------------------*/
.MuiCard-root {
  &.testimonial-card {
    background: $primary-50;
    height: 100%;

    .MuiCardContent-root {
      padding: 32px 40px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .icon {
        height: 58px;
        width: 58px;
        display: block;
        margin-bottom: 14px;
      }

      p {
        font-weight: 600;
        color: $primary-text;
        padding-bottom: 24px;
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;

        span {
          display: block;
          font-weight: 600;
          color: $primary;
        }

        small {
          display: block;
          font-weight: 400;
          color: $secondary-text;
        }

        img {
          display: block;
          height: 48px;
          width: auto;
        }
      }
    }
  }
}

/* engineering culture
--------------------------------------------------------------------------------------------------------*/
.engineering-culture {
  .image-wrapper {
    justify-content: flex-start;

    img {
      max-width: 540px;
      width: auto;
      height: auto;
    }
  }
  .content {
    padding-left: 24px;
  }

  h3 {
    color: $primary-text;
  }

  p {
    color: $secondary-text;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;

    li {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-right: 24px;

      img {
        display: block;
        height: 24px;
        width: 24px;
      }

      span {
        color: $secondary-text;
      }
    }
  }
}

/* faq
--------------------------------------------------------------------------------------------------------*/
.faq {
  padding-top: 100px;
  padding-bottom: 100px;

  .image-wrapper {
    padding-top: 84px;
    img {
      max-width: 440px;
      margin-top: -8px;
      height: auto;
      width: auto;
    }
  }
}

/* request proposal
--------------------------------------------------------------------------------------------------------*/
.request-proposal {
  border-radius: 24px;
  background: $primary;
  padding: 80px 60px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
  overflow: hidden;

  h3 {
    color: $white;
    max-width: 962px;
    text-align: center;
  }

  > span {
    color: rgba(255, 255, 255, 0.88);
    display: block;
    text-align: center;
    padding-bottom: 8px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 40px;

    li {
      small {
        @include subtitle-3();
        color: rgba(255, 255, 255, 0.88);
        text-transform: uppercase;
      }

      a {
        display: flex;
        gap: 8px;
        color: $white;

        &:hover {
          text-decoration: underline;
        }

        img {
          display: block;
          height: 24px;
          width: 24px;
          margin-top: 4px;
        }

        span {
          color: $white;
          padding-bottom: 0;
        }
      }
    }
  }

  .MuiButton-root {
    min-width: 248px;

    &::after {
      background: $primary;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }

    .text {
      color: $primary;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      background-color: $white;

      .text {
        color: $white;
      }
    }
  }

  .oval-left {
    position: absolute;
    bottom: 44px;
    left: -127px;
  }

  .oval-right {
    position: absolute;
    top: -77px;
    right: -68px;
  }
}

/* footer
--------------------------------------------------------------------------------------------------------*/
footer {
  a {
    display: block;
  }

  img {
    display: block;
    width: 192px;
    height: 100%;
  }

  .company-description {
    @include body-3();
    color: $secondary-text;
  }

  strong {
    color: $primary-text;
    padding-bottom: 6px;
  }

  .site-links {
    a {
      padding: 6px 0;
      @include body-2();
      color: $secondary-text;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .contact-info {
    li {
      display: flex;
      gap: 12px;

      img {
        display: block;
        height: 24px;
        width: 24px;
        margin: 2px 0;
      }

      a {
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
        color: $secondary-text;
        transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
          color: $primary;
          text-decoration: underline;

          span {
            color: $primary;
          }
        }
      }

      span {
        color: $secondary-text;
      }
    }
  }

  hr {
    border: 1px solid $light-border;
    margin: 48px 0 0;
  }

  .bottom {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
      @include body-3();
      color: $tertiary-text;
    }

    .MuiIconButton-root {
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
}

/* vertical counter
--------------------------------------------------------------------------------------------------------*/
.vertical-counter {
  display: flex;
  justify-content: center;
  gap: 24px;

  ul {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &:nth-child(1) {
      margin-top: 80px;
    }

    &:nth-child(3) {
      margin-top: 126px;
    }

    li {
      min-height: 228px;
      border-radius: 24px;
      background: $primary;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      span {
        @include heading-3();
        color: $white;
        display: block;
        padding-bottom: 16px;
      }

      p {
        @include body-2-medium();
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
      }
    }
  }
}

/* vision and mission
--------------------------------------------------------------------------------------------------------*/
.vision-and-mission {
  p {
    max-width: 1024px;
    margin: 0 auto;
  }
}

/* section details
--------------------------------------------------------------------------------------------------------*/
.section-details {
  .image-wrapper {
    img {
      max-width: 480px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 48px;

    p {
      color: $secondary-text;
    }

    .bullet-point {
      display: flex;
      gap: 8px;

      img {
        display: block;
        height: 24px;
        width: 24px;
        margin-top: 2px;
      }

      p {
        font-weight: 500;
      }
    }
  }
}

/* it consulting card
--------------------------------------------------------------------------------------------------------*/
.MuiCard-root {
  &.it-consulting-card {
    padding: 32px;
    border-radius: 24px;
    border: 1px solid $light-border;
    position: relative;
    height: 100%;
    transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transform: translateY(-10px);
      box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
        rgb(0 0 0 / 4%) 0px 10px 10px -5px;
    }

    .MuiCardContent-root {
      padding: 0;

      img {
        display: block;
        margin-bottom: 22px;
        width: 59px;
        height: auto;
      }

      h6 {
        font-weight: 700;
        color: $primary-text;
        padding-bottom: 16px;
      }

      p {
        color: $secondary-text;
      }

      span {
        color: $white;
        text-shadow: -2px -2px 0 $light-border, 2px -2px 0 $light-border,
          -2px 2px 0 $light-border, 2px 2px 0 $light-border,
          0 -2px 0 $light-border, 0 2px 0 $light-border, -2px 0 0 $light-border,
          2px 0 0 $light-border;
        position: absolute;
        right: 32px;
        top: 32px;
      }
    }
  }
}

/* consider hiring developer
--------------------------------------------------------------------------------------------------------*/
.consider-hiring-developer {
  border-radius: 24px;
  background: $primary;
  padding: 60px;
  position: relative;
  overflow: hidden;

  h5 {
    color: $white;
    max-width: 1024px;
    margin: 0 auto;
  }

  p {
    color: rgba(255, 255, 255, 0.88);
    font-weight: 500;
    max-width: 948px;
    margin: 0 auto;
  }

  img {
    position: absolute;
    width: auto;
    height: auto;

    &.right {
      top: -72px;
      right: -102px;
    }

    &.left {
      bottom: -92px;
      left: -66px;
    }
  }
}

/* benefits of hiring
--------------------------------------------------------------------------------------------------------*/
.benefits-of-hiring {
  .MuiCard-root {
    .MuiCardContent-root {
      display: flex;
      align-content: center;
      flex-direction: column;
      padding: 0;

      img {
        display: block;
        height: 76px;
        width: auto;
        margin-bottom: 28px;
      }

      p {
        color: $secondary-text;
      }
    }
  }
}

/* pricing methods
--------------------------------------------------------------------------------------------------------*/
.pricing-methods {
  padding-top: 100px;
  padding-bottom: 100px;

  .MuiCard-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $light-border;
    transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transform: translateY(-10px);
      box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
        rgb(0 0 0 / 4%) 0px 10px 10px -5px;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $light-border;
      padding: 40px 40px 32px;

      h6 {
        text-transform: uppercase;
      }

      img {
        display: block;
        height: 48px;
        width: 48px;
      }
    }
    .MuiCardContent-root {
      padding: 24px 40px 40px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .bullet-points-list {
        margin-bottom: 40px;
      }

      a {
        margin: auto auto 0;
      }
    }
  }
}

/* bullet-points-list
--------------------------------------------------------------------------------------------------------*/
.bullet-points-list {
  li {
    margin-bottom: 24px;

    ul {
      li {
        margin-bottom: 8px;
        position: relative;

        &.title {
          display: flex;
          gap: 12px;

          img {
            display: block;
            height: 24px;
            width: 24px;
            margin-top: 2px;
          }

          p {
            color: $primary-text;
          }
        }

        &:not(.title) {
          @include body-2();
          color: $secondary-text;
          padding-left: 37px;

          &::after {
            content: "";
            display: block;
            position: absolute;
            height: 6px;
            width: 6px;
            left: 15px;
            top: 10px;
            background: $tertiary-text;
            border-radius: 10px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* technologies content
--------------------------------------------------------------------------------------------------------*/
.technologies-content {
  .image-wrapper {
    img {
      max-width: 480px;
    }
  }
}

/* visit our portfolio
--------------------------------------------------------------------------------------------------------*/
.visit-our-portfolio {
  background: #004b8f;
  padding: 80px 60px 0;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  h4 {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: $white;
    padding-bottom: 40px;
    position: relative;
    z-index: 1;
  }

  .MuiButton-root {
    display: flex;
    margin: 0 auto 64px;
    position: relative;
    z-index: 1;
  }

  img {
    &.section-img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    &:not(.section-img) {
      position: absolute;
    }

    &.oval-one {
      top: -153px;
      left: 50%;
      transform: translateX(calc(-50% + 187px)) !important;
    }

    &.oval-two {
      top: 322px;
      left: -122px;
    }

    &.oval-three {
      bottom: -57px;
      right: -102px;
    }

    &.grid-bg {
      bottom: -133px;
      left: 50%;
      transform: translateX(-50%) !important;
      z-index: 0;
      width: 100%;
    }
  }
}

/* industry problems
--------------------------------------------------------------------------------------------------------*/
.industry-problems {
  display: flex;
  flex-direction: column;

  > p {
    padding-bottom: 80px;
    color: $primary-text;
  }

  .MuiChip-root {
    margin-left: auto;
    margin-right: auto;
  }

  .MuiCard-root {
    border: 1px solid $light-border;
    height: 100%;
    transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      transform: translateY(-10px);
      box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
        rgb(0 0 0 / 4%) 0px 10px 10px -5px;
    }

    .MuiCardContent-root {
      padding: 32px 40px;

      p {
        text-shadow: -4px -4px 0px #0487ff, 4px -4px 0px #0487ff,
          -4px 4px 0px #0487ff, 4px 4px 0px #0487ff, 0px 4px 0px #0487ff,
          0px -4px 0px #0487ff, 4px 0px 0px #0487ff, -4px 0px 0px #0487ff;
        opacity: 0.16;
        color: $white;
      }

      h6 {
        font-weight: 700;
        padding-bottom: 12px;
        color: $primary-text;
      }

      span {
        color: $secondary-text;
        display: block;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding-top: 8px;

        li {
          flex-basis: 50%;
          max-width: 50%;
          display: flex;
          padding: 8px 8px 8px 0;
          gap: 8px;

          img {
            height: 24px;
            width: 24px;
            display: block;
          }

          span {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.15px;
            padding-top: 1px;
          }
        }
      }
    }
  }
}

/* industry solutions
--------------------------------------------------------------------------------------------------------*/
.industry-solutions {
  padding-top: 100px;
  padding-bottom: 100px;

  .subtext {
    color: $secondary-text;
  }

  .MuiContainer-root {
    display: flex;
    flex-direction: column;
  }

  .MuiChip-root {
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    padding-left: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h5 {
      color: $primary-text;
    }

    p {
      color: $secondary-text;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 48px;
    padding-right: 48px;
    gap: 24px;
    height: 100%;

    li {
      max-width: 100%;
      padding: 16px;
      border-radius: 12px;
      display: flex;
      gap: 12px;
      background: $white;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        transform: translateY(-10px);
        box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
          rgb(0 0 0 / 4%) 0px 10px 10px -5px;
      }

      img {
        display: block;
        height: 24px;
        width: 24px;
        margin-top: 2px;
      }

      p {
        color: $secondary-text;
      }
    }
  }
}

/* solution impact
--------------------------------------------------------------------------------------------------------*/
.solution-impact {
  border-radius: 24px;
  padding: 64px 80px;
  background: $primary-50;
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;

    &.left {
      left: 0;
      top: 0;
    }

    &.right {
      bottom: 0;
      right: 0;
    }
  }

  h4 {
    color: $primary;
    padding-bottom: 32px;
  }

  ul {
    li {
      display: flex;
      gap: 12px;

      &:not(:last-child) {
        margin-bottom: 32px;
      }

      img {
        display: block;
        height: 24px;
        width: 24px;
        margin: 2px;
      }

      p {
        color: $secondary-text;
      }
    }
  }
}

/* contact us form wrapper
--------------------------------------------------------------------------------------------------------*/
.contact-us-form-wrapper {
  .contact-info {
    background: $primary-50;
    border-radius: 24px;
    padding: 40px 32px 0;
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    min-height: 532px;
    display: flex;
    flex-direction: column;
    height: 100%;

    > img {
      width: auto;
      height: auto;

      &.border {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotateY(180deg);
      }

      &:not(.border) {
        display: block;
        margin: auto auto 0;
        max-width: 100%;
      }
    }

    ul {
      padding-bottom: 24px;

      li {
        display: flex;
        gap: 12px;
        margin-bottom: 24px;

        span {
          color: $secondary-text;
        }

        img {
          display: block;
          height: 28px;
          width: 28px;
        }

        a {
          display: flex;
          gap: 12px;

          &:hover {
            text-decoration: underline;

            span {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .contact-us-form {
    max-width: 536px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

/* portfolio card
--------------------------------------------------------------------------------------------------------*/
.MuiCard-root {
  &.portfolio-card {
    border-radius: 0;

    .MuiCardContent-root {
      padding: 0;

      .card-image {
        min-height: 280px;
        border-radius: 12px;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        overflow: hidden;

        .card-overlay {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 255, 255, 0.24);
          backdrop-filter: blur(16px);
          transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
          opacity: 0;
          visibility: hidden;
          padding: 24px;

          .button-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 12px;
          }
        }
      }

      p {
        color: $primary-text;
      }
    }

    &:hover {
      .card-image {
        .card-overlay {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/* site loader
--------------------------------------------------------------------------------------------------------*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: rgba(255, 255, 255, 0.88);
  z-index: 999;
  backdrop-filter: blur(8px);
}

.loader img {
  display: block;
  height: 48px;
  margin-bottom: 40px;
}

/* portfolio projects
--------------------------------------------------------------------------------------------------------*/
.portfolio-projects {
  .MuiPagination-root {
    margin-top: 48px;

    .MuiPagination-ul {
      justify-content: center;
    }
  }
}

/* scroll to top button
--------------------------------------------------------------------------------------------------------*/
.MuiIconButton-root {
  &.scroll-to-top-btn {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
    transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);

    &.show {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      background: rgba(4, 135, 255, 0.32);
      filter: blur(6px);
      border-radius: 30px;
      bottom: -8px;
      left: 2px;
      z-index: 0;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:focus {
      &::before {
        filter: blur(0);
        bottom: 0;
      }
    }
  }
}

/* file upload
--------------------------------------------------------------------------------------------------------*/
.file-upload {
  width: 100%;
  padding: 24px;
  background: $primary-50;
  border: 2px dashed $primary-200;
  border-radius: 8px;
  position: relative;

  &.dragging {
    border-color: $primary;
  }

  > img {
    display: block;
    height: 40px;
    width: 40px;

    margin: 0 auto 12px;
  }

  p {
    @include subtitle-3();
    color: $primary-text;
    text-align: center;
    padding-bottom: 4px;
  }

  label {
    color: $primary;
    cursor: pointer;
  }

  span {
    display: block;
    text-align: center;
    @include body-3();
    color: $secondary-text;
  }

  input {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: 0;
    position: absolute;
    right: -999px;
  }

  .MuiIconButton-root {
    position: absolute;
    top: 12px;
    right: 12px;
    background: $white;
    padding: 6px;

    img {
      width: 16px;
      height: 16px;

      &.active {
        margin-left: -16px;
      }
    }
  }
}

/* media queries
--------------------------------------------------------------------------------------------------------*/
@media (min-width: 1200px) {
  header {
    ul {
      li {
        &.has-submenu {
          a {
            &:hover {
              img {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1439px) {
  .MuiCard-root {
    &.portfolio-card {
      .MuiCardContent-root {
        .card-image {
          min-height: 220px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  body {
    &.show-sidebar {
      overflow: hidden;
    }
  }

  .show-sidebar {
    header {
      ul {
        left: 0;
        z-index: 99;
      }

      .sidebar-overlay {
        opacity: 1;
        visibility: visible;
        z-index: 48;
      }
    }
  }

  header {
    min-height: 76px;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;

    .MuiIconButton-root {
      display: inline-flex;
    }

    > a {
      &:not(.logo) {
        margin-left: auto;
      }
    }

    .logo {
      margin-left: 16px;

      img {
        height: 40px;
        width: 160px;
      }
    }

    ul {
      position: fixed;
      top: 0;
      left: -280px;
      bottom: 0;
      background: $white;
      display: block;
      width: 280px;
      z-index: 1;
      padding: 16px 12px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
      overflow-y: auto;

      li {
        margin-bottom: 8px;

        &.has-submenu {
          a {
            &.menu-expanded {
              background: $primary-50;
              color: $primary;

              img {
                transform: rotate(180deg);
              }
            }

            img {
              display: block;
            }
          }
        }

        .submenu {
          position: relative;
          opacity: 1;
          visibility: visible;
          padding: 0;
          border-radius: 0;
          box-shadow: none;
          width: auto;
          padding: 8px 0 0;

          li {
            margin-bottom: 0;

            a {
              padding-left: 34px;
              padding-right: 16px;
              position: relative;
              border-radius: 30px;

              &::before {
                content: "";
                display: block;
                height: 4px;
                width: 4px;
                border-radius: 10px;
                background: $tertiary-text;
                left: 18px;
                top: 20px;
                position: absolute;
                transition: all 180ms cubic-bezier(0.4, 0, 0.2, 1);
              }

              &:hover {
                color: $primary;
                background: transparent;

                &::before {
                  background: $primary;
                }
              }

              &.active {
                &::before {
                  background: $primary;
                }
              }
            }
          }
        }
      }
    }
  }

  .banner {
    &.hero-banner {
      padding-top: 180px;
      padding-bottom: 120px;

      h2 {
        padding-bottom: 24px;
      }

      p {
        padding-bottom: 48px;
      }
    }

    &.industry-details-banner {
      margin-bottom: 80px;
    }

    &.service-details-banner {
      padding-top: 76px;

      .banner-content {
        .right {
          img {
            height: 400px;
          }
        }
      }
    }
  }

  .outer-card {
    margin: 0;
    border-radius: 0;
  }

  .our-services {
    padding-top: 80px;
  }

  .industries-we-serve {
    padding-bottom: 80px;
  }

  .how-we-work {
    ul {
      max-width: 480px;
      li {
        .step {
          max-width: 245px;
        }
      }
    }

    img {
      width: 320px;

      &.illustration-2 {
        right: -100px;
      }

      &.illustration-1 {
        left: -100px;
      }
    }
  }

  .vertical-counter {
    gap: 16px;

    ul {
      gap: 16px;
    }
  }

  .banner {
    &.page-banner {
      padding-top: 124px;
      min-height: 456px;
      padding-bottom: 48px;
    }
  }

  .section-details {
    .content {
      padding-left: 24px;
    }
  }

  .pricing-methods {
    padding-top: 80px;
    padding-bottom: 80px;

    .MuiCard-root {
      .top {
        padding: 32px 32px 24px;
      }

      .MuiCardContent-root {
        padding: 24px 32px 40px;
      }
    }
  }

  .engineering-culture {
    .image-wrapper {
      justify-content: center;

      img {
        max-width: 100%;
      }
    }

    .content {
      padding-left: 0;
    }
  }

  .industry-problems {
    .MuiCard-root {
      .MuiCardContent-root {
        ul {
          display: block;

          li {
            max-width: 100%;
            padding-right: 0;
          }
        }
      }
    }
  }

  .industry-solutions {
    padding-top: 80px;
    padding-bottom: 80px;

    .content {
      padding-left: 16px;
    }

    ul {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .solution-impact {
    padding: 64px 48px;
  }

  .MuiIconButton-root {
    &.scroll-to-top-btn {
      bottom: 24px;
      right: 24px;
    }
  }
}

@media (max-width: 991px) {
  .banner {
    padding-top: 124px;

    &.page-banner {
      min-height: 360px;
    }

    &.service-details-banner {
      h2 {
        padding-bottom: 32px;
      }

      .banner-content {
        .right {
          img {
            height: 300px;
          }
        }
      }
    }
  }

  .about-it-solution {
    .image-wrapper {
      img {
        max-width: 480px;
      }
    }
  }

  .counter {
    padding-top: 40px;
    padding-bottom: 40px;

    ul {
      li {
        span {
          font-size: 40px;
          line-height: 56px;
          letter-spacing: 0.25px;
          padding-bottom: 4px;
        }
      }
    }
  }

  .our-services {
    padding-top: 64px;
  }

  .software-development-technologies {
    padding: 64px;
  }

  .how-we-work {
    img {
      width: 260px;
    }
  }

  .faq {
    padding-top: 80px;
    padding-bottom: 80px;

    .MuiGrid-root {
      .MuiGrid-item {
        &:first-child {
          display: none;
        }
      }
    }

    h3 {
      text-align: center;
    }
  }

  .request-proposal {
    padding-top: 64px;
    padding-bottom: 48px;
  }

  .vertical-counter {
    max-width: 600px;
    margin: 0 auto 16px;

    ul {
      li {
        min-height: 180px;

        span {
          font-size: 40px;
          line-height: 56px;
          letter-spacing: 0.25px;
          padding-bottom: 8px;
        }
      }
    }
  }

  .section-details {
    .image-wrapper {
      margin-bottom: 16px;

      img {
        max-width: 400px;
      }
    }

    .content {
      padding-left: 0;
    }
  }

  .service-details-content {
    > p {
      padding-bottom: 64px;
    }
  }

  .pricing-methods {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .testimonials {
    padding-bottom: 76px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 40px;
      top: 0;
      bottom: 0;
      left: -40px;
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
      z-index: 1;
    }

    .content {
      padding-top: 0;
      max-width: 100%;

      h3 {
        text-align: center;
        padding-bottom: 16px !important;
      }

      p {
        text-align: center;
        padding-bottom: 16px;
      }
    }

    .slider-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -40px;

      .slick-slider {
        max-width: 408px;

        .MuiButtonBase-root {
          &.slick-arrow {
            bottom: 0;
            top: unset;
            left: 50%;

            &.slick-next {
              transform: translateX(calc(-50% + 28px));
            }

            &.slick-prev {
              transform: translateX(calc(-50% - 28px));
            }
          }
        }

        .slick-list {
          .slick-track {
            .slick-slide {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .industry-problems {
    > p {
      padding-bottom: 64px;
    }

    .MuiCard-root {
      .MuiCardContent-root {
        padding: 24px;
      }
    }
  }

  .industry-solutions {
    .content {
      padding-left: 0;
    }

    ul {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      gap: 16px;

      li {
        max-width: 100%;
      }
    }
  }

  .solution-impact {
    padding: 48px;
  }

  .contact-us-form-wrapper {
    .contact-info {
      max-width: 100%;
      min-height: auto;
    }

    .contact-us-form {
      max-width: 100%;
    }
  }

  .MuiCard-root {
    &.portfolio-card {
      .MuiCardContent-root {
        .card-image {
          min-height: 280px;
        }
      }
    }
  }

  .portfolio-projects {
    margin-top: -24px;

    .MuiTabs-root {
      overflow: visible;

      > .MuiButtonBase-root {
        border: 2px solid $light-border;
        border-radius: 4px;

        &.Mui-disabled {
          display: none;
        }
      }

      .MuiTabs-flexContainer {
        gap: 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .banner {
    &.hero-banner {
      padding-bottom: 64px;
      padding-top: 144px;
    }

    &.industry-details-banner {
      margin-bottom: 64px;
    }

    &.service-details-banner {
      margin-bottom: 64px;

      h2 {
        text-align: center;
      }

      .banner-content {
        flex-direction: column;
        padding-bottom: 24px;

        .left {
          padding-right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 24px;
        }
      }
    }
  }

  .counter {
    padding-top: 24px;
    padding-bottom: 24px;

    ul {
      flex-wrap: wrap;

      li {
        // max-width: 50%;
        flex-basis: 50%;
        margin-top: 24px;
        margin-bottom: 24px;
      }
    }
  }

  .industries-we-serve {
    padding-bottom: 64px;
  }

  .slider-card {
    .top {
      padding-right: 0;
    }
  }

  .slick-slider {
    margin-bottom: 116px;

    .MuiButtonBase-root {
      &.slick-arrow {
        top: unset;
        bottom: -76px;
        left: 50%;

        &.slick-prev {
          right: unset;
          transform: translateX(calc(-50% - 28px));
        }

        &.slick-next {
          transform: translateX(calc(-50% + 28px));
        }
      }
    }
  }

  .MuiCard-root {
    &.industry-card {
      height: 480px;
    }
  }

  .software-development-technologies {
    padding: 60px 48px;

    .content {
      z-index: 2;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 24px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.32) 100%
      );
      z-index: 1;
    }
  }

  .how-we-work {
    .outer-card {
      padding-top: 64px;
      padding-bottom: 42px;
    }

    img {
      width: 200px;

      &.illustration-2 {
        right: -80px;
      }

      &.illustration-1 {
        left: -80px;
      }
    }
  }

  .our-clients {
    padding: 64px 0;

    ul {
      margin-bottom: 48px;
    }
  }

  .faq {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .request-proposal {
    padding: 48px;

    ul {
      flex-direction: column;
      gap: 4px;
    }
  }

  .vertical-counter {
    margin-bottom: 8px;
  }

  .service-details-content {
    > p {
      padding-bottom: 48px;
    }
  }

  .section-details {
    .image-wrapper {
      margin-bottom: 0;
    }
  }

  footer {
    hr {
      margin-top: 32px;
    }
  }

  .consider-hiring-developer {
    padding: 48px;

    h5 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.1px;

      padding-bottom: 16px !important;
    }
  }

  .testimonials {
    &::after {
      left: -24px;
    }

    &::before {
      right: -24px;
    }

    .slider-wrapper {
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .visit-our-portfolio {
    padding: 48px 48px 0;

    h4 {
      padding-bottom: 24px;
    }

    .MuiButton-root {
      margin-bottom: 48px;
    }

    img {
      &.oval-two {
        left: -152px;
      }

      &.oval-three {
        bottom: -110px;
      }

      &.grid-bg {
        bottom: 0;
      }
    }
  }

  .industry-problems {
    > p {
      padding-bottom: 48px;
    }
  }

  .industry-solutions {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .solution-impact {
    padding: 32px 40px;
    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .MuiCard-root {
    &.portfolio-card {
      .MuiCardContent-root {
        .card-image {
          min-height: 220px;
        }
      }
    }
  }

  .portfolio-projects {
    .MuiPagination-root {
      margin-top: 40px;
    }
  }
}
@media screen and (max-width:1030px) and (min-width:767px) {
  .request-proposal ul{
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  header {
    padding-left: 16px;
    padding-right: 16px;

    .logo {
      margin-left: 8px;

      img {
        &:not(.mobile-logo) {
          display: none;
        }
        &.mobile-logo {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .banner {
    &.hero-banner {
      padding-bottom: 48px;
      padding-top: 124px;
    }

    h2 {
      font-size: 32px;
      line-height: 44px;
      letter-spacing: 0.04px;
    }
  }

  .MuiCard-root {
    &.service-card {
      .MuiCardContent-root {
        padding: 24px 24px 8px;
      }
      .MuiCardActions-root {
        padding: 0 24px 24px;
      }
    }

    &.industry-card {
      height: 480px;
    }

    &.it-consulting-card {
      padding: 24px;
    }
  }

  .software-development-technologies {
    padding: 48px 24px;
  }

  .how-we-work {
    ul {
      max-width: 320px;

      li {
        .step {
          max-width: 165px;
        }
      }
    }
    img {
      display: none;
    }
  }

  .why-choose-us {
    .MuiCard-root {
      .MuiCardContent-root {
        padding: 24px;
      }
    }
  }

  .request-proposal {
    padding-left: 24px;
    padding-right: 24px;

    > span {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.15px;
      padding-bottom: 16px;
    }

    ul {
      margin-bottom: 32px;
      li {
        a {
          align-items: center;
          gap: 6px;

          img {
            margin-top: 0;
          }

          span {
            word-break: break-all;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.25px;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .vertical-counter {
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;

    ul {
      flex-basis: calc(50% - 16px);
      max-width: calc(50% - 16px);

      &:nth-child(3) {
        align-self: flex-end;
        margin-top: 0;
        flex-basis: 100%;
        max-width: 100%;
        margin-left: 8px;
        margin-right: 8px;
      }

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  footer {
    hr {
      margin-top: 24px;
    }

    .bottom {
      flex-direction: column-reverse;
      padding-bottom: 24px;

      .social-links {
        margin-bottom: 4px;
      }
    }
  }

  .consider-hiring-developer {
    padding: 32px;
  }

  .pricing-methods {
    .MuiCard-root {
      .top {
        padding: 24px;
      }

      .MuiCardContent-root {
        padding: 24px 24px 32px;

        .bullet-points-list {
          margin-bottom: 24px;
        }
      }
    }
  }

  .MuiCard-root {
    &.testimonial-card {
      .MuiCardContent-root {
        padding: 24px;

        p {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.25px;
        }
      }
    }
  }

  .testimonials {
    &::before {
      right: -16px;
      width: 16px;
    }

    &::after {
      width: 16px;
      left: -16px;
    }

    .slider-wrapper {
      margin-left: -16px;
      margin-right: -16px;

      .slick-slider {
        max-width: 340px;

        .slick-list {
          .slick-track {
            .slick-slide {
              min-height: 440px;
            }
          }
        }
      }
    }
  }

  .visit-our-portfolio {
    padding-left: 24px;
    padding-right: 24px;
  }

  .solution-impact {
    padding-left: 24px;
    padding-right: 24px;

    h4 {
      padding-bottom: 24px;
    }

    > img {
      &.left {
        left: -10px;
        top: -10px;
      }

      &.right {
        right: -10px;
        bottom: -10px;
      }
    }
  }

  .MuiIconButton-root {
    &.scroll-to-top-btn {
      bottom: 16px;
      right: 16px;
    }
  }
}


/* Added for responsive about us page 4 small card section*/
@media (max-width: 575px) {
  .vertical-counter ul {
      flex-basis: calc(100% - 16px);
      max-width: calc(100% - 16px);
  }
  .vertical-counter ul:nth-child(2) {
  flex-direction: row;
  }
}


