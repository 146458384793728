/* Standard Spacing Classes
---------------------------------------------------------------------------------------------------------------*/
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 4px !important;
}

.m-2 {
  margin: 8px !important;
}

.m-3 {
  margin: 16px !important;
}

.m-4 {
  margin: 24px !important;
}

.m-5 {
  margin: 32px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-3 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-4 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-5 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-4 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-5 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mt-5 {
  margin-top: 32px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 16px !important;
}

.mr-4 {
  margin-right: 24px !important;
}

.mr-5 {
  margin-right: 32px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mb-5 {
  margin-bottom: 32px !important;
}

.mb-6 {
  margin-bottom: 48px !important;
}

.mb-9 {
  margin-bottom: 120px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 16px !important;
}

.ml-4 {
  margin-left: 24px !important;
}

.ml-5 {
  margin-left: 32px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 4px !important;
}

.p-2 {
  padding: 8px !important;
}

.p-3 {
  padding: 16px !important;
}

.p-4 {
  padding: 24px !important;
}

.p-5 {
  padding: 32px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-3 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-4 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-5 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-5 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 16px !important;
}

.pt-4 {
  padding-top: 24px !important;
}

.pt-5 {
  padding-top: 32px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 16px !important;
}

.pr-4 {
  padding-right: 24px !important;
}

.pr-5 {
  padding-right: 32px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 16px !important;
}

.pb-4 {
  padding-bottom: 24px !important;
}

.pb-5 {
  padding-bottom: 32px !important;
}

.pb-6 {
  padding-bottom: 48px !important;
}

.pb-7 {
  padding-bottom: 64px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 16px !important;
}

.pl-4 {
  padding-left: 24px !important;
}

.pl-5 {
  padding-left: 32px !important;
}

/* media queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1199px) {
  .mb-9 {
    margin-bottom: 80px !important;
  }
}

@media (max-width: 991px) {
  .pb-7 {
    padding-bottom: 48px !important;
  }
}

@media (max-width: 767px) {
  .mb-9 {
    margin-bottom: 64px !important;
  }

  .pb-6 {
    padding-bottom: 40px !important;
  }
}
